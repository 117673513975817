@import "node_modules/@siemens/ngx-datatable/index";
@import "node_modules/@siemens/ngx-datatable/themes/bootstrap";
@import "node_modules/@siemens/ngx-datatable/assets/icons";

.ngx-datatable {
  &.bootstrap {
    border: 1px solid #dddddd;

    .datatable-body {
      .datatable-body-row {
        .datatable-body-cell {
          padding: 0.5rem;
        }
      }
    }
  }

  datatable-header-cell *,
  datatable-summary-row * {
    font-weight: bold !important;
  }

  .datatable-footer {
    background-color: var(--ion-color-primary) !important;
  }

  &.bootstrap {
    .datatable-row-even .datatable-row-left {
      background-color: rgb(242, 242, 242);
    }
    .datatable-row-left {
      background-color: #ffffff;
    }
  }

  &.bootstrap .datatable-header-cell {
    border: 1px solid transparent;
  }
}

.ngx-datatable.fixed-row
  .datatable-scroll
  .datatable-body-row
  .datatable-body-cell {
  display: flex;
  align-items: center;
}

.dark-layout .ngx-datatable {
  &.bootstrap .datatable-row-left {
    background-color: #111111;
  }

  &.bootstrap {
    border: 1px solid var(--header-footer-background-color);
  }

  &.bootstrap .datatable-body-row {
    border: 1px solid #6b6b6b;
  }
  &.bootstrap .datatable-header-cell {
    border: 1px solid transparent;
  }

  &.bootstrap .datatable-body .datatable-body-row.datatable-row-even {
    background-color: #222222;
    .datatable-row-left {
      background-color: #222222;
    }
  }
}
