/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Custom theme colors */
@import "theme/variables";
/* Core CSS required for Ionic components to work properly */
@import "node_modules/@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "node_modules/@ionic/angular/css/normalize.css";
@import "node_modules/@ionic/angular/css/structure.css";
@import "node_modules/@ionic/angular/css/typography.css";
@import "node_modules/@ionic/angular/css/display.css";
/* Optional CSS utils that can be commented out */
@import "node_modules/@ionic/angular/css/padding.css";
@import "node_modules/@ionic/angular/css/float-elements.css";
@import "node_modules/@ionic/angular/css/text-alignment.css";
@import "node_modules/@ionic/angular/css/text-transformation.css";
@import "node_modules/@ionic/angular/css/flex-utils.css";
@import "node_modules/@ng-select/ng-select/themes/default.theme.css";

@import "./scss/mailmanager";
@import "./scss/ngx-datatable";
@import "./scss/mat-table";

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/maps";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/alert";

@import "node_modules/quill/dist/quill.snow.css";

.sc-ion-input-md-h,
.sc-ion-input-ios-h {
  --placeholder-color: #8c8c8c;
  --placeholder-opacity: 0.8;
  --padding-top: 5px;
  --padding-bottom: 5px;
}

div.alert-radio-group.sc-ion-alert-md {
  max-height: 100%;
}

.item-interactive-disabled.sc-ion-label-md-h:not(.item-multiple-inputs),
.item-interactive-disabled:not(.item-multiple-inputs) .sc-ion-label-md-h {
  opacity: 0.5;
}

.native-input[disabled].sc-ion-input-md {
  opacity: 0.8;
}

ion-label {
  margin-top: 5px;
  margin-bottom: 5px;
}

.kundenprozess {
  width: 100%;
  height: 100%;
  border: none;
}

.header__heading {
  --min-height: 25px;
  line-height: 25px;
  --height: fit-content;
  text-align: center;
}

.header__title {
  font-weight: bold;
  height: 100%;
  display: block;
  vertical-align: middle;
  padding: 0;
}

.subheader__heading {
  text-align: center !important;
}

.subheader__ion-toolbar {
  --background: var(--ion-background-color);
  padding-top: 10px;
  padding-bottom: 10px;
}

.text-bold {
  font-weight: bold;
  font-size: 17px;
}

.item.item-trns {
  border-color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
  color: white;
}

.back-btn {
  height: fit-content;
  width: auto;
  margin-top: auto;
  margin-bottom: auto;

  ion-icon {
    size: auto;
  }
}

.uebersicht__akt__title {
  font-weight: bold;
  display: flex !important;
  align-items: center;
}

.uebersicht__item {
  padding: 0;
  padding-inline-start: 0 !important;
  cursor: pointer;
}

@mixin status($color) {
  box-shadow: inset 6px 0 0 0 $color;

  ion-grid {
    box-shadow: inset 6px 0 0 0 $color;
    height: 100%;
    padding-left: 10px;
  }

  .uebersicht__akt__title {
    color: $color !important;
  }
}

.status_faellig {
  @include status(#b80000);
}

.status_RECHNUNGSPRUEFUNG_OFFEN {
  @include status(#003d98);
}

.status_kt_offen {
  @include status(#ff980e);
}

.status_anstehend {
  @include status(black);
  .uebersicht__akt__title {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    padding-right: 5px;
  }
}

.status_bericht_versandt {
  @include status(rgb(151, 151, 151));
}

.status_bericht_nicht_versandt {
  @include status(rgb(47, 168, 13));
}

.status_unbekannt {
  @include status(rgb(255, 192, 203));
}

.clickable:hover {
  cursor: pointer;
}

.no-padding-inline-start {
  padding-inline-start: 0 !important;
}

.not-active-filter {
  --background: none !important;
  --background-activated: none !important;
  --background-focused: none !important;
  --background-hover: none !important;
  color: var(--ion-color-primary);
  border: var(--ion-color-primary);
  border: 1px solid var(--ion-color-primary);
  border-radius: 5px;

  .ion-activated {
    --background: none !important;
  }
}

.more_padding {
  padding-top: 12px;
  padding-bottom: 12px;
  width: 100%;
}

@font-face {
  font-family: "SourceSansPro";
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/SourceSansPro-Light.ttf");
}

@font-face {
  font-family: "SourceSansPro";
  font-style: normal;
  font-weight: bold;
  src: url("assets/fonts/SourceSansPro-Regular.ttf");
}

@font-face {
  font-family: "SourceSansPro";
  font-style: normal;
  font-weight: 900;
  src: url("assets/fonts/SourceSansPro-SemiBold.ttf");
}

.bold-text {
  font-weight: bold;
}

* {
  font-family: SourceSansPro, sans-serif;
  font-weight: normal;
}

.header-translucent-ios ion-toolbar {
  --opacity: 1;
}

ion-item-divider {
  --padding-start: 10px;
}

.ion-list,
.list-md {
  padding-top: 0 !important;
}

ion-item-divider {
  color: var(--ion-color-step-850, #262626) !important;
  background: var(--ion-color-step-100, #e6e6e6) !important;
  /*
  color: var(--ion-color-primary, #3880ff) !important;
  */
}

.kundentermin_heute ion-icon {
  display: inline-block !important;
}

#kontaktpage {
  img {
    max-width: 80vw;
    height: auto;
  }

  a {
    text-decoration: none;
  }

  strong {
    font-weight: bold !important;
    font-size: 110%;
  }
}

.fullheight {
  height: 100%;
}

.xc {
  display: flex;
  align-items: center;
  justify-content: center;
}

.backButtonFai {
  margin-right: 2%;
}

.card-title-search {
  font-size: 18px;
  line-height: 18px;

  .selected {
    font-weight: 400;
  }
}

.global-search input {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.search_result ion-item {
  font-size: 14px;
}

.sum-row-container {
  display: block;
  width: 100%;
}

.content-left {
  width: 49%;
  text-align: left;
  display: inline-block;
}

.content-right {
  width: 50%;
  text-align: right;
  display: inline-block;
}

.result-col {
  padding: 0;
}

.gruppe {
  font-weight: 900;
}

.untergruppe {
  font-style: italic;
}

.result-item ion-col {
  padding: 10px 0;
}

@media screen and (max-width: 768px) {
  ion-card-header {
    padding: 10px;
  }

  ion-card {
    width: 100%;
    margin: 1px 0 0 0;
    padding: 0;
    border-radius: 0;
  }
  .hide-on-mobile {
    display: none;
  }

  .hide-ion-select-icon-on-mobile::part(icon) {
    display: none;
  }

  .textvorlage-quick-select-bericht {
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: 768px) {
  .hide-on-desktop {
    display: none;
  }
}

.editBtn,
.deleteBtn {
  border-radius: 0;
}

app-gesamtkalkulation ion-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal-wrapper {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

.padding-fixed {
  padding-top: 8px;
  padding-bottom: 8px;
}

.not-editable {
  color: grey !important;
}

ion-card-header {
  background: rgba(0, 0, 0, 0.03);

  ion-card-title {
    color: #004899;
  }
}

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

.note-editor.note-airframe,
.note-editor.note-frame {
  width: 100%;
  margin: 15px 0;
}

.invisible {
  visibility: hidden !important;
}

.hidden-xs-up {
  display: none !important;
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
}

.hidden-xl-down {
  display: none !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  margin: 0;
  padding: 0;
  background-size: 100%;
  background-position: center;
  width: 20px;
  box-shadow: none;
  border: none;
  outline: none;
}

@media only screen and (min-width: 992px) {
  .tl-xl {
    text-align: left;
  }

  .tr-xl {
    text-align: right;
  }
}

@media (prefers-color-scheme: dark) {
  input[type="date"] {
    background: black;
  }
}

.textvorlageModal {
  --min-height: 30% !important;
  --max-height: 90% !important;
  --min-width: 30% !important;
  --max-width: 90% !important;
}

.customSelectFC {
  border: 0;
  width: 100%;
  padding: 10px;

  &:focus-visible {
    outline: none;
    box-shadow: none;
  }

  * {
    border: 0 !important;
    box-shadow: none;
  }
}

.selectedStatusOption {
  background-color: var(--ion-color-primary) !important;
  color: white !important;
  border: 1px solid white !important;
  margin: 5px;
}

.notSelectedStatusOption {
  color: var(--ion-color-primary) !important;
  border: 1px solid var(--ion-color-primary) !important;
  margin: 5px;
}

.download-btn-cd {
  font-size: 30px;
  color: black;
  font-weight: bold;
  margin: auto;
  padding: 0;
  display: inline-flex;
  vertical-align: middle;
}

.ng-select .ng-select-container {
  min-height: 28px !important;
  height: fit-content !important;
}

a.cd-read-mode {
  cursor: pointer;
}

.danger-outline {
  --background: none !important;
  --background-activated: none !important;
  --background-focused: none !important;
  --background-hover: none !important;
  color: var(--ion-color-danger);
  border: var(--ion-color-danger);
  border: 1px solid var(--ion-color-danger);
  border-radius: 5px;
}

@media (max-width: 991px) {
  .cc-revoke.cc-bottom {
    bottom: 10em !important;
    left: -1.5em !important;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
    transform: rotate(90deg);
  }
}

@media (min-width: 991px) {
  .cc-animate.cc-revoke.cc-bottom {
    transform: translateY(0px) !important;
  }
}

app-kundenprozess {
  h1 {
    margin-top: -23px;
    margin-bottom: -10px;
  }
}

.korrektur-vorschau {
  text-align: justify;
  padding: 10px;
  height: 100%;
  width: 100%;
}

.__cropro__0_toolbar_button.__cropro__0_toolbar_button_colors:has(a),
.__cropro__0_toolbar_straightener_block,
.__cropro__1_toolbar_button.__cropro__1_toolbar_button_colors:has(a),
.__cropro__1_toolbar_straightener_block,
.__cropro__2_toolbar_button.__cropro__2_toolbar_button_colors:has(a),
.__cropro__2_toolbar_straightener_block,
.__cropro__3_toolbar_button.__cropro__3_toolbar_button_colors:has(a),
.__cropro__3_toolbar_straightener_block,
.__cropro__4_toolbar_button.__cropro__4_toolbar_button_colors:has(a),
.__cropro__4_toolbar_straightener_block,
.__cropro__5_toolbar_button.__cropro__5_toolbar_button_colors:has(a),
.__cropro__5_toolbar_straightener_block,
.__cropro__6_toolbar_button.__cropro__6_toolbar_button_colors:has(a),
.__cropro__6_toolbar_straightener_block,
.__cropro__7_toolbar_button.__cropro__7_toolbar_button_colors:has(a),
.__cropro__7_toolbar_straightener_block,
.__cropro__8_toolbar_button.__cropro__8_toolbar_button_colors:has(a),
.__cropro__8_toolbar_straightener_block,
.__cropro__9_toolbar_button.__cropro__9_toolbar_button_colors:has(a),
.__cropro__9_toolbar_straightener_block {
  display: none !important;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.kundenprozess-faircheck-video {
  width: 100%;
  height: auto;
}

body.default-layout {
  color-scheme: light;
}

app-header {
  ion-segment {
    border-radius: 0;
  }

  ion-segment-button {
    --color: var(--header-footer-color);
  }
}

.header__faircheck-toolbar,
.tabs__tab-bar,
app-header ion-segment {
  --background: var(--header-footer-background-color);
}

app-header ion-row {
  background-color: var(--header-footer-background-color);
}

body.dark-layout {
  color-scheme: dark;
}

@media only screen and (min-width: 992px) {
  .limit-container-width {
    margin: 0 auto;
    max-width: 960px;
  }
  app-header {
    width: 100%;
  }
}

@media only screen and (min-width: 1320px) {
  .limit-container-width {
    margin: 0 auto;
    max-width: 1140px;
  }
  app-header {
    width: 100%;
  }
}

@media only screen and (min-width: 1320px) and (min-height: 800px) {
  .bigger-modal {
    --width: 1000px;
    --height: 800px;
  }

  .textvorlageModal {
    --height: 1000px;
    --width: 600px;
    --min-height: 300px;
    --min-width: 300px;
  }

  .resize-corner {
    display: block !important;
    position: absolute;
    width: 38px;
    height: 34px;
    bottom: 0;
    right: 0;
    cursor: se-resize;
  }
}

.resize-corner {
  display: none;
}

.upload-select-dropdown .ng-dropdown-panel {
  width: 280px !important;
}

.akt-datatable-align-center .datatable-row-center {
  datatable-body-cell {
    display: flex !important;
    align-items: center !important;
    .datatable-body-cell-label {
      white-space: normal;
    }
  }
}

.cursor-pointer:hover {
  cursor: pointer;
}

.kundenstatusliste-datatable-align-center .datatable-row-center {
  datatable-body-cell {
    display: flex !important;
    align-items: center !important;
    .datatable-body-cell-label,
    .datatable-body-cell-label div {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.kundenstatusliste-datatable-font-size {
  font-size: larger !important;
}

#editorInkParamsToolbar {
  display: none !important;
}

.no-horizontal-borders {
  border-right: none !important;
  border-left: none !important;
}

body.dark-layout {
  .hidden-scrollbar {
    scrollbar-color: var(--ion-item-background) var(--ion-item-background);
  }

  ion-toolbar ion-button {
    --ion-toolbar-color: var(--ion-color-light-contrast);
  }

  .folder-node {
    --border-color: #848484;
  }

  ion-popover::part(arrow) {
    --background: #393939;
  }
  ion-popover ion-content::part(background) {
    --background: #393939;
  }
  .ng-dropdown-panel.ng-select-bottom {
    background-color: var(--ion-item-background);
    border-color: var(--ion-item-background);
    -webkit-box-shadow:
      0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
    box-shadow:
      0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  .ng-select-container {
    background-color: var(--ion-item-background) !important;
    border-color: #737373 !important;
    color: white;
  }
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    color: white;
    background-color: var(--ion-item-background);
    border-color: var(--ion-item-background);
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
  .ng-dropdown-panel
    .ng-dropdown-panel-items
    .ng-option.ng-option-selected.ng-option-marked {
    color: white;
    background-color: #1a2d45;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    color: white;
    background-color: #272727;
  }

  .mat-timepicker-panel {
    --mat-timepicker-container-background-color: var(--ion-item-background);
    --mat-option-label-text-color: var(--color);
  }
  .ql-editor.ql-blank::before {
    color: white;
  }
  .ql-stroke {
    stroke: white;
  }
  .ql-picker.ql-header .ql-picker-label::before {
    color: white;
  }

  .ql-snow .ql-picker.ql-header .ql-picker-item::before {
    color: white;
  }
  .ql-picker-options {
    background-color: #393939;
  }

  .ql-tooltip {
    background-color: #393939;
    color: white;
  }
}

ion-popover[remove-padding="true"] ion-content::part(scroll) {
  padding: 0px;
}

body.default-layout {
  .hidden-scrollbar {
    scrollbar-color: transparent transparent;
  }
  ion-toolbar ion-title,
  ion-toolbar ion-button {
    color: var(--ion-color-light);
  }

  .header__faircheck-toolbar ion-button {
    color: initial;
  }

  .mailmanager-toolbar-transparent ion-title {
    color: initial;
  }

  ion-select {
    color: initial;
  }

  .ng-dropdown-panel.ng-select-bottom {
    -webkit-box-shadow:
      0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
    box-shadow:
      0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
}

.mat-sort-header-content {
  font-weight: bold;
}

.ngx-datatable .datatable-header .datatable-header-cell .sort-btn {
  position: absolute;
  top: 15px;
  right: 5px;
}

ion-item-divider ion-label {
  font-weight: bolder;
}

.display-grid {
  display: grid !important;
}

.display-none {
  display: none !important;
}

.horizontal-scroll-container {
  overflow-x: scroll;
  white-space: nowrap;
}
.no-wrap {
  display: inline-block;
  white-space: nowrap;
}

.no-wrap ion-row {
  flex-wrap: nowrap !important;
}

.no-wrap ion-col {
  flex: 0 0 auto;
  min-width: 60px;
}

.ng-select.ng-touched.ng-invalid .ng-select-container {
  border-color: red;
}

.text-transform-unset {
  text-transform: unset !important;
}

.contrast-color {
  color: var(--ion-color-light-contrast);
}

.anzeige-nicht-moeglich-container {
  margin: 2.5em 1em;
  text-align: center;
  font-size: large;
}

.spinner-small {
  width: 20px !important;
  height: 20px !important;
}

.pointer-events-all {
  pointer-events: all;
}

.pointer-events-none {
  pointer-events: none;
}

.show-number-spinner-controls {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    opacity: 1;
  }

  input[type="number"] {
    appearance: auto;
  }
}

.align-content-center {
  align-content: center !important;
}

.ion-item-full-height {
  height: 100%;
  ::part(native) {
    height: 100%;
  }
}

.ion-item-full-height::part(native) {
  height: 100%;
  align-items: baseline;
}

.interactive-toast {
  cursor: pointer;
}

.color-primary {
  color: var(--ion-color-primary) !important;
}

.color-secondary {
  color: var(--ion-color-secondary) !important;
}

.color-danger {
  color: var(--ion-color-danger) !important;
}

.smaller-modal {
  --width: 600px;
  --height: 300px;
}

.ios ion-modal ion-header ion-button {
  --color: white;
}

.bericht-dropdown::part(icon) {
  min-width: 20px;
}

.bericht-dropdown::part(container) {
  margin-right: 24px;
}

.kalkulation-grid {
  ion-col {
    display: flex;
    align-items: center;
  }
}

.required-star {
  color: red;
  margin-left: 4px;
}

.invalid-feedback {
  color: red;
  width: 100%;
  margin: 0.3em;
}

ion-modal::part(backdrop) {
  background-color: rgba(0, 0, 0, 0.5);
}

ion-modal::part(content) {
  box-shadow:
    0 4px 6px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.06);
  border-radius: 0.358rem;
}

.overflow-visible {
  overflow: visible !important;
}

.fixed-parent-select {
  width: 90px;
  --text-overflow: ellipsis;
}

.fixed-parent-select ion-popover {
  --width: auto;
}

.fixed-parent-select ion-popover .popover-content,
.fixed-parent-select ion-select-option {
  white-space: nowrap;
  overflow: visible;
}

.button-select {
  width: 90px;
}

.auto-size-select-popoover {
  --width: auto;
  --min-width: none;
  --max-width: none;
}

.auto-size-select-popoover .popover-content {
  white-space: nowrap;
  overflow: visible;
}

mat-form-field {
  --mdc-filled-text-field-container-shape: 0px;
  --mdc-filled-text-field-container-color: var(--ion-item-background);
  --mdc-filled-text-field-label-text-color: var(--color);
  --mdc-filled-text-field-input-text-color: var(--color);
  --mdc-filled-text-field-hover-label-text-color: var(--color);

  width: 100%;

  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }
}

.mdc-floating-label {
  margin-top: 7px !important;
}

quill-editor {
  max-width: 100%;

  .ql-editor {
    overflow-wrap: anywhere;
  }
}

.no-height {
  height: 0px !important;
}
