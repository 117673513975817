.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  height: 100%;
}
mat-table {
  width: 1000px;
  max-width: 1000px;
  margin-bottom: 1rem;
  display: table;
  border-collapse: collapse;
  margin: 0px;
}
mat-row,
mat-header-row {
  display: table-row;
}
mat-cell,
mat-header-cell {
  word-wrap: initial;
  display: table-cell;
  padding: 0px 5px;
  line-break: unset;
  width: auto;
  white-space: normal;
  overflow: hidden;
  vertical-align: middle;
}

.mat-sort-header-container {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 5px;
}

.mat-sort-header-content {
  width: 80%;
  align-items: baseline !important;
  flex-direction: column !important;
}

body.dark-layout {
  .mat-mdc-table {
    background-color: var(--ion-background-color);
  }
  .mat-mdc-row,
  .mat-mdc-header-row {
    color: white;
  }

  .mat-mdc-row {
    border-top: 1px solid #6b6b6b;
    border-bottom: 1px solid #6b6b6b;
  }
  .mat-mdc-row:nth-child(even) {
    background-color: #222222;
  }
}

body.default-layout {
  .mat-mdc-row:nth-child(even) {
    background-color: #ececec;
  }
}

.focused-akt-datatable {
  filter: drop-shadow(0px 0px 4px var(--ion-color-secondary));
}
