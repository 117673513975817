.mat-tree {
  background-color: transparent !important;
}

.highlight-folder {
  color: var(--ion-color-secondary);
  ion-label {
    font-weight: bolder;
  }
}

.folder-node {
  --background: transparent;
  cursor: pointer;
  width: 100%;
}

.mailmanager-toolbar-transparent {
  --background: transparent !important;
}

.unset-focus-highlight:focus-visible {
  outline: unset !important;
}

.mat-column-date {
  width: 200px;
  max-width: 200px;
}
